import { mapActions, mapGetters } from 'vuex'
import PreviewImage from '../previewImage/PreviewImage.vue'
import { resizePhoto } from '../../constants/file'

export default {
    name: 'Camera',

    components: {
        PreviewImage
    },

    props: {
        component: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        view: {
            type: String,
            default: null
        },
        error: {
            type: String,
            default: null
        },
        scanning: {
            type: Boolean,
            default: false
        },
        reset: {
            type: Function,
            default: null
        }
    },

    data() {
        return {
            src: null
        }
    },

    computed: {
        ...mapGetters({
            form: 'form/form'
        }),

        field() {
            return this.form[this.component] || {}
        },

        photo() {
            return this.field && this.field[this.type]
        },

        ref() {
            return `cameraInput_${this.component}_${this.type}`
        }
    },

    methods: {
        ...mapActions({
            setForm: 'form/setForm'
        }),

        resetPhoto() {
            // Reset old photo to always trigger watcher
            this.$refs[this.ref].value = null
        },

        async setPhoto() {
            let resizedPhoto

            if (typeof this.reset === 'function') {
                this.deletePhoto()
            }

            const photo = this.$refs[this.ref].files[0] || null
            try {
                resizedPhoto = await resizePhoto(photo)
            } catch {
                // Just catching
            }

            if (resizedPhoto) {
                this.setForm({
                    key: this.type,
                    value: resizedPhoto,
                    step: this.component
                })
            }
        },

        deletePhoto() {
            this.reset()

            this.setForm({
                key: this.type,
                value: null,
                step: this.component
            })
        }
    }
}
