import EventBus from '../../../utils/eventBus'
import stepMixin from '../../../mixins/stepMixin'
import checkTaskMixin from '../../../mixins/checkTaskMixin'
import TextList from '../../textList/TextList.vue'
import FormInput from '../../form/formInput/FormInput.vue'
import FormRadio from '../../form/formRadio/FormRadio.vue'
import Camera from '../../camera/Camera.vue'
import { errorMessage } from '../../../constants/alertMessages'
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'Vin',

    mixins: [stepMixin, checkTaskMixin],

    components: {
        TextList,
        FormInput,
        FormRadio,
        Camera
    },

    data() {
        return {
            correctVehicle: null,
            correctVehicleValues: [
                {
                    label: 'Ja',
                    value: true
                },
                {
                    label: 'Nein',
                    value: false
                }
            ]
        }
    },

    computed: {
        ...mapGetters({
            form: 'form/form',
            ocr: 'ocr/ocr',
            loggedInUser: 'user/loggedInUser'
        }),

        vehicleItems() {
            return [
                {
                    label: 'Hersteller',
                    value: this.form.vehicleByVin.manufacturer,
                    error:
                        this.isDifferentVehicle &&
                        this.form.vehicleByVin.manufacturer !==
                            this.form.vehicleByLicensePlate.manufacturer
                },
                {
                    label: 'Modell',
                    value: this.form.vehicleByVin.model,
                    error:
                        this.isDifferentVehicle &&
                        this.form.vehicleByVin.model !==
                            this.form.vehicleByLicensePlate.model
                },
                {
                    label: 'Farbe',
                    value: this.form.vehicleByVin.color,
                    error:
                        this.isDifferentVehicle &&
                        this.form.vehicleByVin.color !==
                            this.form.vehicleByLicensePlate.color
                },
                {
                    label: 'Kennzeichen',
                    value: this.form.vehicleByVin.license_plate,
                    error:
                        this.isDifferentVehicle &&
                        this.form.vehicleByVin.license_plate !==
                            this.form.vehicleByLicensePlate.license_plate
                },
                {
                    label: 'FIN',
                    class: 'twoCols',
                    value: this.form.vehicleByVin.vin,
                    error:
                        this.isDifferentVehicle &&
                        this.form.vehicleByVin.vin !==
                            this.form.vehicleByLicensePlate.vin
                }
            ]
        },

        isDifferentVehicle() {
            let isDifferent = false

            if (
                this.form &&
                this.form.vehicleByVin &&
                Object.keys(this.form.vehicleByVin).length > 0 &&
                this.form.vehicleByLicensePlate &&
                Object.keys(this.form.vehicleByLicensePlate).length > 0 &&
                (this.form.vehicleByVin.manufacturer !==
                    this.form.vehicleByLicensePlate.manufacturer ||
                    this.form.vehicleByVin.model !==
                        this.form.vehicleByLicensePlate.model ||
                    this.form.vehicleByVin.license_plate !==
                        this.form.vehicleByLicensePlate.license_plate ||
                    this.form.vehicleByVin.color !==
                        this.form.vehicleByLicensePlate.color ||
                    this.form.vehicleByVin.vin !==
                        this.form.vehicleByLicensePlate.vin)
            ) {
                isDifferent = true
            }

            return isDifferent
        },

        vehicleFound() {
            return (
                this.form &&
                this.form.vehicleByVin &&
                Object.keys(this.form.vehicleByVin).length > 0
            )
        },

        vin: {
            get() {
                return this.field.vin
            },
            set(value) {
                this.field.vin = value
            }
        },

        vinPhoto() {
            return this.field.vinPhoto
        }
    },

    methods: {
        ...mapActions({
            scanVin: 'ocr/scanVin',
            resetScanError: 'ocr/resetScanError',
            setVehicle: 'form/setVehicle',
            fetchVehicle: 'form/fetchVehicle',
            setForm: 'form/setForm'
        }),

        validate() {
            let isValid = true

            if (this.isEmpty(this.field.vin) || this.field.vin.length !== 17) {
                isValid = false
            }

            if (
                this.isEmpty(this.field.correctVehicle) ||
                this.field.correctVehicle === false
            ) {
                isValid = false
            }

            this.$emit('update:stepIsValid', isValid)
        },

        async search() {
            let response

            if (this.field.vin) {
                response = await this.fetchVehicle({
                    params: {
                        vin: this.field.vin,
                        type: this.isCheckin
                            ? 'checkin'
                            : this.isCheckout
                            ? 'checkout'
                            : null
                    },
                    token: this.loggedInUser?.token
                })

                // Handling errors
                if (response && response.error) {
                    const statusCode = response.error

                    if (statusCode === 403) {
                        // Token has been expired or is invalid, redirect to logout
                        this.$router.push({
                            name: 'Logout',
                            params: { message: errorMessage.SESSION_EXPIRED }
                        })
                    }

                    if (statusCode === 500) {
                        // Error from API
                        EventBus.$emit('showDialog', {
                            dialog: {
                                name: 'error',
                                type: 'hint',
                                showContact: true,
                                errors: [
                                    'Es gab ein Problem bei der Kommunikation mit der Schnittstelle.'
                                ]
                            }
                        })
                    }
                }
            }
        },

        reload() {
            this.resetCorrectVehicle()
            this.triggerSearch()
        },

        resetCorrectVehicle() {
            this.setForm({
                key: 'correctVehicle',
                value: null,
                step: 'vin'
            })
        },

        resetPhoto() {
            this.resetScanError('vin')
        }
    },

    watch: {
        vin() {
            this.field.vin = this.field.vin?.toUpperCase() ?? ''
            this.resetCorrectVehicle()
            this.triggerSearch()
            this.resetPhoto()
        },

        correctVehicle(val) {
            EventBus.$emit('hideDialog')

            if (val === false) {
                EventBus.$emit('showDialog', {
                    dialog: {
                        name: 'error',
                        type: 'hint',
                        showContact: true,
                        errors: [
                            'Anhand der eingegebenen FIN wurde nicht das korrekte Fahrzeug gefunden.'
                        ]
                    }
                })

                // Reset vehicle and customer
                this.setVehicle()
            } else {
                // Set vehicle and customer
                this.setVehicle(this.form.vehicleByVin)
            }
        },

        vinPhoto(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.scanVin(newVal)
            }
        }
    }
}
